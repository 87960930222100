import { render, staticRenderFns } from "./zonaTanah.vue?vue&type=template&id=f8ad35ea&scoped=true"
import script from "./zonaTanah.vue?vue&type=script&lang=js"
export * from "./zonaTanah.vue?vue&type=script&lang=js"
import style0 from "./zonaTanah.vue?vue&type=style&index=0&id=f8ad35ea&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8ad35ea",
  null
  
)

export default component.exports
import {QCard,QCardSection,QInput,QBtn,QSeparator,QBadge,QBtnGroup,QDialog,QCardActions,QFile,QIcon,QImg,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCard,QCardSection,QInput,QBtn,QSeparator,QBadge,QBtnGroup,QDialog,QCardActions,QFile,QIcon,QImg})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
