

import {gmapApi} from 'vue2-google-maps'
import * as geolib from 'geolib';
import * as XLSX from 'xlsx';

// const API_BKKBN = "https://statistik-server.konaweselatankab.go.id/apiStatistikBkkbn/";


export default {
  data() {
    return {
      excelData: null,
      jsonData: null,
      // Tombol Modal
      tambah: false,
      edit: false,
      lihat: false,
      modal_lokasi: false,
      mdl_hapus: false,
      catatan: false, 

      modal_text : false,
      modal_json : false,
      modal_excel : false,

      // Tombol Save Modal
      simpan1: false,
      simpan2: false,
      //

      // Import JSON
      selectedFile: null,
      isJSONStructureValid: false,
      expectedJSONStructure: [{
        // Define your expected JSON structure here
        // Example: "name": "", "email": "", "age": 0
        "lat": "", "lng": ""
      }],

      id : '',
      status : '',
      id_kecamatan: '',
     
      form : {
        id: '',
        nik: '',
        nama: '',
        alamat: '',
        kecamatan_id : '',
        nama_kecamatan : '',
        des_kel_id : '',
        nama_des_kel: '',
        rwrt: '',
        no_telp: '',
        catatan: '',
        status_pengajuan: null,
        file: '',
      },
      lokasi: [],
      centroid: null,
      //
      file_old: "",
      file_path: this.$store.state.url.URL_APP + "uploads/",

      no: 0,
      id: '',
      url: {
        GET_URL: this.$store.state.url.URL_APP + "api/v1/web_zona_tanah/",

        GET_KECAMATAN: this.$store.state.url.URL_APP + "api/v1/petadasarnew/"
      },
      list_data: [],
      kecamatan: [],
      desa: [],

      page_first: 1,
      page_last: 0,
      cari_value: "",

    }
  },
  methods: {
    convertToJson() {
      if (!this.excelData) {
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0]; // Assuming you have one sheet
        const jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

        this.lokasi = jsonData;
        this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
      };
      reader.readAsArrayBuffer(this.excelData);
    },

    handleFileChange(event) {
      this.selectedFile = event.target.files[0];
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.excelData = file;
      }
    },
    uploadFile() {
      if (!this.selectedFile) {
        alert("Please select a file.");
        return;
      }
      
      // Now you can process the selected file (e.g., parse the JSON data).
      // Use FileReader or any other suitable method to read the file.
      const fileReader = new FileReader();
      fileReader.onload = () => {
        try {
          const jsonData = JSON.parse(fileReader.result);
          // Check if the JSON structure matches the expected structure
          this.isJSONStructureValid = this.compareJSONStructure(jsonData, this.expectedJSONStructure);
          // Do something with the JSON data, such as storing it in a data property.
          this.lokasi = jsonData;
          this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
        } catch (error) {
          alert("Error parsing JSON: " + error.message);
        }
      };
      fileReader.readAsText(this.selectedFile);
      
    },
    compareJSONStructure(objA, objB) {
      // Recursively compare the structure of two objects
      const typeA = typeof objA;
      const typeB = typeof objB;

      if (typeA !== typeB) {
        return false;
      }

      if (typeA !== "object" || objA === null || objB === null) {
        return true;
      }

      const keysA = Object.keys(objA);
      const keysB = Object.keys(objB);

      if (keysA.length !== keysB.length) {
        return false;
      }

      for (const key of keysA) {
        if (!keysB.includes(key) || !this.compareJSONStructure(objA[key], objB[key])) {
          return false;
        }
      }

      return true;
    },
  
    getKecamatan: function() {
                this.cek_load_data = true;
                fetch(this.url.GET_KECAMATAN + "kecamatan", {
                  method: "POST",
                  headers: {
                    "content-type": "application/json",
                    authorization: "kikensbatara " + localStorage.token
                  },
                  body: JSON.stringify({
                    kecamatan_id : this.id_kecamatan
                    
                  })
                })
                  .then(res => res.json())
                  .then(res_data => {
                      
                    var tampung=[];

                    var kode = '';

                      for(var i=0;i<res_data.length;i++){

                        if(res_data[i].hasil.kode<10){
                            kode = `0${res_data[i].hasil.kode}`;
                        }
                        else{
                            kode = `${res_data[i].hasil.kode}`
                        }


                        tampung.push({
                          // kecamatan_id: `${res_data[i].hasil.no_prop}.0${res_data[i].hasil.no_kab}.0${res_data[i].hasil.kode}`, 
                          kecamatan_id: `${res_data[i].hasil.no_prop}.0${res_data[i].hasil.no_kab}.${kode}`, 
                          nama_kecamatan: res_data[i].hasil.uraian
                        })
                      }

                    
                      this.kecamatan = tampung;
                      this.cek_load_data = false;
                  });
            },

    getDesa: function() {
        this.cek_load_data = true;


        fetch(this.url.GET_KECAMATAN + "desa", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
          kecamatan_id : this.form.kecamatan_id.id,
      })

        })
          .then(res => res.json())
          .then(res_data => {


              var tampung=[];

              var no_kec = '';


                for(var i=0;i<res_data.length;i++){

                  if(res_data[i].no_kec<10){
                      no_kec = `0${res_data[i].no_kec}`;
                  }
                  else{
                      no_kec = `${res_data[i].no_kec}`
                  }


                  tampung.push({
                    // kecamatan_id: `${res_data[i].hasil.no_prop}.0${res_data[i].hasil.no_kab}.0${res_data[i].hasil.no_kec}`, 
                      des_kel_id: `${res_data[i].no_prop}.0${res_data[i].no_kab}.${no_kec}.${res_data[i].kode}`, 
                      nama_des_kel: res_data[i].uraian
                    })
                  }

                // Tambah Ranowila
                if(this.form.kecamatan_id.id == '74.05.18'){
                  tampung.push({
                    // kecamatan_id: `${res_data[i].hasil.no_prop}.0${res_data[i].hasil.no_kab}.0${res_data[i].hasil.no_kec}`, 
                    des_kel_id: '74.05.18.2006', 
                    nama_des_kel: 'RANOWILA'
                  })
                }
                if(this.form.kecamatan_id.id == '74.05.07'){
                
                    // Remove Ranowila
                      tampung.splice(16, 1);
                  
                }

               

                this.desa = tampung;
                this.cek_load_data = false;

          });
    },

    mapping: function(data) {

      var tampung = [];

      for(var i = 0; i < data.length; i++) {
          var obj = data[i];

          tampung.push({"lat":parseFloat(obj.lat),"lng":parseFloat(obj.lng)});
      }

      return tampung;

    },


    getView: function() {
      this.cek_load_data = true;
      fetch(this.url.GET_URL + "view", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
            data_ke: this.page_first,
            cari_value: this.cari_value,
            id : this.id,
            status : this.status
          })
        })
        .then(res => res.json())
        .then(res_data => {

          
          this.list_data = res_data[0].data1;
          this.page_last = res_data.lengthy;
          this.cek_load_data = false;
          
          // console.log(this.list_data)
          // 


        });
    },


    calculatePolygonCentroid() {
      const coordinates = this.lokasi.map((point) => ({
        latitude: point.lat,
        longitude: point.lng,
      }));

      const centroid = geolib.getCenter(coordinates);

      this.centroid = {
        lat: centroid.latitude,
        lng: centroid.longitude,
      };
    },


    addData: function() {

      this.calculatePolygonCentroid();

      var formData = new FormData();

      formData.append("nik", this.form.nik);
      formData.append("nama", this.form.nama);
      formData.append("alamat", this.form.alamat);
      formData.append("kecamatan_id", this.form.kecamatan_id.id);
      formData.append("nama_kecamatan", this.form.kecamatan_id.text);
      formData.append("des_kel_id", this.form.des_kel_id.id);
      formData.append("nama_des_kel", this.form.des_kel_id.text);
      formData.append("rwrt", this.form.rwrt);
      formData.append("catatan", this.form.catatan);
      formData.append("no_telp", this.form.no_telp);
      formData.append("status_pengajuan", 1);
      formData.append("file", this.form.file);


      formData.append("lokasi", JSON.stringify(this.lokasi));

      formData.append("marker", JSON.stringify(this.centroid));


      fetch(this.url.GET_URL + "addData", {
        method: "POST",
        headers: {
          authorization: "kikensbatara " + localStorage.token
        },
        body: formData
      }).then(res_data => {
        this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
        this.getView();
        this.tambah = false;
      })
    },

    editData: function() {

      this.calculatePolygonCentroid();
      
      var formData = new FormData();

      formData.append("id", this.form.id);
      formData.append("nik", this.form.nik);
      formData.append("nama", this.form.nama);
      formData.append("alamat", this.form.alamat);
      formData.append("kecamatan_id", this.form.kecamatan_id.id);
      formData.append("nama_kecamatan", this.form.kecamatan_id.text);
      formData.append("des_kel_id", this.form.des_kel_id.id);
      formData.append("nama_des_kel", this.form.des_kel_id.text);
      formData.append("rwrt", this.form.rwrt);
      formData.append("catatan", this.form.catatan);
      formData.append("no_telp", this.form.no_telp);
      formData.append("status_pengajuan", 1);
      formData.append("file", this.form.file);
      formData.append("file_old", this.file_old);

      formData.append("lokasi", JSON.stringify(this.lokasi));
      formData.append("marker", JSON.stringify(this.centroid));


      fetch(this.url.GET_URL + "editData", {
        method: "POST",
        headers: {
          authorization: "kikensbatara " + localStorage.token
        },
        body: formData
      }).then(res_data => {
        this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
        this.getView();
        this.edit = false;
      });
    },

    selectData: function(data, string) {

      if(string == "edit"){
        this.edit = true;

        this.form = {
          id: data.id,
          nik: data.nik,
          nama: data.nama,
          alamat: data.alamat,
          kecamatan_id: data.kecamatan_id,
          des_kel_id: data.des_kel_id,
          nama_kecamatan : data.nama_kecamatan,
          nama_des_kel: data.nama_des_kel,
          status_pengajuan: data.status_pengajuan,
          rwrt: data.rwrt,
          catatan: data.catatan,
          no_telp: data.no_telp,
          file: data.file,
        };
        this.lokasi = data.lokasi;
        this.file_old = data.file;
      }
      else if(string == "lokasi"){
        this.modal_lokasi = true;

        this.form = {
          
          nama_kecamatan: data.nama_kecamatan,
          nama_des_kel: data.nama_des_kel,
        }
        this.lokasi = data.lokasi;
        this.centroid = data.marker
        
        
      }
      else if(string == "hapus"){
        this.mdl_hapus = true;

        this.form = {
          id: data.id,
          nik: data.nik,
          nama: data.nama,
          alamat: data.alamat,
          kecamatan_id: data.kecamatan_id,
          des_kel_id: data.des_kel_id,
          nama_kecamatan : data.nama_kecamatan,
          status_pengajuan : data.status_pengajuan,
          nama_des_kel: data.nama_des_kel,
          rwrt: data.rwrt,
          catatan: data.catatan,
          no_telp: data.no_telp,
          file: data.file,
        };
        this.lokasi = data.lokasi;
        this.file_old = data.file;

        
      }
      else if (string == "lihat") {
        this.lihat = true;

        this.form.file = data.file;
        
      }
      else if (string == "catatan") {
        this.catatan = true;

        this.form = {
          
          nama_kecamatan: data.nama_kecamatan,
          nama_des_kel: data.nama_des_kel,
          catatan: data.catatan
        }
        
      }


      // CKEDITOR.instances.editor1.setData(data.isi);
    },


    removeData: function(idnya, file) {
      fetch(this.url.GET_URL + "removeData", {
        method: "POST",
        headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
        },
        body: JSON.stringify({
          id: idnya,
          file: file
        })
      }).then(res_data => {
        this.getView();
        this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
        this.mdl_hapus = false;
      });
    },

    simulateProgress(number) {
      // we set loading state
      this[`simpan${number}`] = true
      // simulate a delay
      setTimeout(() => {
        // we're done, we reset loading state
        this[`simpan${number}`] = false
      }, 3000)
    },

    cari_data: function() {

      this.page_first = 1;
      this.getView();
    },

    Notify : function(message, positive, icon){
            this.$q.notify({
              message: message,
              color: positive,
              icon: icon,
              position : 'top',
              timeout: 500,
            })
          },

    onFileSelected: function(event) {
      this.form.file = event.target.files[0];

    },

    btn_prev: function() {
      this.cek_load_data = true;
      if (this.page_first > 1) {
        this.page_first--;
        this.no -= 10;
      } else {
        this.page_first = 1;
       
      }
      this.getView();
    },

    btn_next: function() {

      if (this.page_first >= this.page_last) {
        this.page_first == this.page_last
      } else {
        this.page_first++;
        this.no += 10;
      }
      this.getView();
    },

    addLokasi: function () {
     this.lokasi.push({ lat: 0 , lng: 0});
    },
    hapusLokasi: function () {
     this.lokasi.splice(-1,1);
    }

  },

  computed: {
    google: gmapApi
  },
  mounted() {
    var get_profile = JSON.parse(localStorage.profile);
      
    this.id = get_profile.id;
    this.status = get_profile.profile.status;
    this.id_kecamatan = get_profile.profile.id_kecamatan;

    this.getKecamatan();
    this.getView();

  }
}
